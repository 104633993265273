


if (!document['_vaadintheme_base-theme_componentCss']) {
  
  document['_vaadintheme_base-theme_componentCss'] = true;
}

if (import.meta.hot) {
  import.meta.hot.accept((module) => {
    window.location.reload();
  });
}

